import { Controller } from '@hotwired/stimulus'

export class RedirectController extends Controller {
  static values = {
    url: String,
    delay: { type: Number, default: 0 }
  }

  redirectTo (url) {
    setTimeout(() => {
      window.location.assign(url)
    }, this.delayValue)
  }
}
